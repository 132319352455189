import { FC } from 'react';
import { map } from 'lodash';
import { useIntl } from 'react-intl';
import { Button, Dialog } from '@mui/material';
import { useLang } from 'src/app/i18n';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { EventAccessibilityEnum, EventStatusEnum } from 'src/app/modules/event-v2/types';
import { useSpecificEventDeeplink } from './specific-event-deeplink.hook';
import { useStyles } from './specific-event-depplink.style';

type SpecificEventDeeplinkProps = {
  isView?: boolean;
  selectId?: string;
  onSelectItem: (id?: string) => void;
};

export const SpecificEventDeeplink: FC<SpecificEventDeeplinkProps> = ({ isView, selectId, onSelectItem }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useLang();
  const {
    showDialog,
    filterState,
    eventList,
    selectedRow,
    isLoading,
    setShowDialog,
    setFilterState,
    setSelectedRow,
    refreshData,
    fetchCategoryDropdown,
    onSort,
    onClear,
    onBack,
    onSubmit,
  } = useSpecificEventDeeplink({ selectId, onSelectItem });
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.buttonContainer}>
        {selectedRow && (
          <div>
            {selectedRow.name[locale]} | {selectedRow.category.name[locale]} |{' '}
            {Translation(`event.common.event_status.${selectedRow.eventStatus.toLowerCase()}`)}
          </div>
        )}
        {!isView && (
          <>
            {selectedRow ? (
              <Button variant="contained" color="secondary" onClick={() => onClear()}>
                {Translation('deeplink.clear.select.event.label')}
              </Button>
            ) : (
              <Button variant="contained" color="secondary" onClick={() => setShowDialog(true)}>
                {Translation('deeplink.select.event.label')}
              </Button>
            )}
          </>
        )}
      </div>
      <Dialog open={showDialog} fullScreen>
        <div className={classes.container}>
          <div>
            <Button variant="contained" color="inherit" onClick={() => onBack()}>
              {Translation('app.button.back')}
            </Button>
          </div>
          <PruFilter
            title={'Select Event Item'}
            itemDef={[
              {
                type: PruFilterItemType.FREE_TEXT,
                field: 'search',
                initialValue: filterState.search,
                displayName: Translation('event.common.name'),
              },
              {
                type: PruFilterItemType.ASYNC_DROPDOWN,
                style: { width: 250 },
                field: 'category',
                initialValue: filterState.category,
                displayName: Translation('event.common.category'),
                initialOptions: [{ displayName: Translation('component.status.all'), value: '' }],
                fetchList: fetchCategoryDropdown,
              },
              {
                type: PruFilterItemType.DROPDOWN,
                field: 'accessibility',
                initialValue: filterState.accessibility,
                displayName: Translation('event.common.accessibility'),
                choices: [
                  { displayName: Translation('component.status.all'), value: '' },
                  ...map(EventAccessibilityEnum, (option) => ({
                    displayName: Translation(`event.common.accessibility.${option.toLowerCase()}`),
                    value: option,
                  })),
                ],
              },
              {
                type: PruFilterItemType.DATE,
                field: 'eventDate',
                initialDate: filterState.eventDate,
                displayName: Translation('event.common.event_date'),
              },
              {
                type: PruFilterItemType.FREE_TEXT,
                field: 'lastUpdatedBy',
                initialValue: filterState.lastUpdatedBy,
                displayName: Translation('event.common.last_updated_by'),
              },
              {
                type: PruFilterItemType.DROPDOWN,
                field: 'eventStatus',
                initialValue: filterState.eventStatus,
                displayName: Translation('title.event.event.status'),
                choices: [
                  { displayName: Translation('component.status.all'), value: '' },
                  ...map(EventStatusEnum, (option) => ({
                    displayName: Translation(`event.common.event_status.${option.toLowerCase()}`),
                    value: option,
                  })),
                ],
              },
            ]}
            onChangeFilter={(data) =>
              setFilterState({
                ...filterState,
                search: data.search,
                category: data.category,
                accessibility: data.accessibility,
                eventDate: data.eventDate,
                createdBy: data.createdBy,
                eventStatus: data.eventStatus,
              })
            }
            fetchData={refreshData}
          />
          <PruTable
            singleSelect
            operationDef={[]}
            columnDef={[
              {
                isId: true,
                hidden: true,
                keyIndex: '_id',
                displayName: '',
                renderData: () => '',
              },
              {
                keyIndex: 'name',
                displayName: Translation('event.common.name'),
                renderData: (row) => row.name[locale] || '-',
              },
              {
                keyIndex: 'category',
                displayName: Translation('event.common.category'),
                renderData: (row) => row.category.name[locale] || '-',
              },
              {
                keyIndex: 'accessibility',
                displayName: Translation('event.common.accessibility'),
                renderData: (row) =>
                  Translation(`event.common.accessibility.${row.category.accessibility.toLowerCase()}`),
              },
              {
                keyIndex: 'eventDate',
                displayName: Translation('event.common.event_date'),
                renderData: (row) => getDefaultDisplayDate(row.eventDate),
                sortable: true,
                replaceSortState: true,
                onSort: (sort) => onSort({ key: 'eventDate', value: sort['eventDate'] }),
              },
              {
                keyIndex: 'createdBy',
                displayName: Translation('event.common.created_by'),
                renderData: (row) => row.createdBy || '-',
              },
              {
                keyIndex: 'lastUpdatedBy',
                displayName: Translation('event.common.last_updated_by'),
                renderData: (row) => row.lastUpdatedBy || '-',
              },
              {
                keyIndex: 'updatedAt',
                displayName: Translation('component.formLabel.last-updated-time'),
                renderData: (row) => getDefaultDisplayDate(row.updatedAt, 'datetime'),
                sortable: true,
                replaceSortState: true,
                onSort: (sort) => onSort({ key: 'updatedAt', value: sort['updatedAt'] }),
              },
              {
                keyIndex: 'eventStatus',
                displayName: Translation('title.event.event.status'),
                renderData: (row) => Translation(`event.common.event_status.${row.eventStatus.toLowerCase()}`),
              },
              {
                keyIndex: 'publishStatus',
                displayName: Translation('component.formLabel.publish-status'),
                renderData: (row) => Translation(`event.common.publish_status.${row.publishStatus.toLowerCase()}`),
              },
            ]}
            isLoading={isLoading}
            onRefresh={refreshData}
            dataSource={eventList?.docs}
            defaultRowsPerPage={20}
            totalPages={eventList?.totalPages}
            totalRecords={eventList?.totalDocs}
            onChangePage={(page, rowsPerPage) => {
              setFilterState({
                ...filterState,
                page,
                limit: rowsPerPage,
              });
              refreshData();
            }}
            bulkSelectCheckboxDisable={(rowData) =>
              ![EventStatusEnum.UPCOMING, EventStatusEnum.ONGOING].includes(rowData.eventStatus)
            }
            currentSelectedRow={(rowData) => setSelectedRow(rowData[0])}
          />
          <div className={classes.footerContainer}>
            <Button variant="contained" color="inherit" onClick={() => onBack()}>
              {Translation('app.button.cancel')}
            </Button>
            <Button variant="contained" color="secondary" onClick={() => onSubmit()}>
              {Translation('app.button.submit')}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
