import React, { FC, useState, useReducer, CSSProperties, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useIntl } from 'react-intl';
import { ApplicationFormEditHistoryListParam, editHistoryParam, PaginateList } from '../../../types/edit-history-types';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useHistory } from 'react-router-dom';
import {
  ErrorFieldType,
  useErrorHandler,
  ErrorFieldDef,
  isValidURL,
  useDebounce,
  fileUpload,
  downloadFile,
} from 'src/app/common/utils';
import { FormControl, Select, FormHelperText, Button } from '@mui/material';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { forEach } from 'lodash';
import { PruDatePicker } from 'src/app/common/components/PruDatePicker';
import PruFilter, {
  PruFilterAsyncDropdownDef,
  PruFilterDateRangeDef,
  PruFilterDropdownDef,
  PruFilterItemType,
} from 'src/app/common/components/PruTable/PruFilter';
import { fetchFeedbackCategoryType } from 'src/app/modules/Feedback/network/feedbackCrud';
import { useDataProvider, getDayStart, getDayEnd } from 'src/app/common/utils';
import { fetchApplicationFormEditHistoryList } from '../../../network/application-form-crud';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import AsyncAutocomplete from 'src/app/common/components/AsyncAutocomplete';
import { exportRegistration, fetchLicenseExamExamOptions } from '../../../network/license-exam-crud';
import { useLang } from 'src/app/i18n';
import { handleDowloadFileError } from '../../../utils';

type RegistrationExportPageProps = ParamsProps;

type PruFilterState = {
  [id: string]: any;
};

const DATE_ERROR_TEXT = 'End date must be after Begin date';

type ModifyFilterAction = {
  type: 'CHANGE_FILTER';
  payload: {
    field: keyof PruFilterState;
    value: Array<Date | null> | string | Date | null;
  };
};

const filterReducer = (state: PruFilterState, action: ModifyFilterAction) => {
  switch (action.type) {
    case 'CHANGE_FILTER': {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
  }
};

type registrationFormState = {
  examType?: string;
  examName?: string;
  examDateFrom: Date | null;
  examDateTo: Date | null;
};

type FieldDef = {
  field: string;
  displayName: string;
  mandatory?: boolean;
  type: string;
  rule?: { [Symbol.replace](string: string, replaceValue: string): string };
};

const initialState: registrationFormState = {
  examType: undefined,
  examName: undefined,
  examDateFrom: null,
  examDateTo: null,
};

const errorFieldConvertor = (fields: PruFilterItemDef[]): ErrorFieldDef[] => {
  const errorFieldDef: ErrorFieldDef[] = [];
  fields.forEach((field) => {
    if (field) {
      errorFieldDef.push({
        name: field.displayName,
        fieldType: ErrorFieldType.MANDATORY,
      });
    }
  });
  return errorFieldDef;
};


type PruFilterItemDef = PruFilterDateRangeDef | PruFilterDropdownDef | PruFilterAsyncDropdownDef;

const RegistrationExportPage: FC<RegistrationExportPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const dispatch = useDispatch();

  const [formState, filterDispatch] = useReducer(filterReducer, initialState);

  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes: commonClasses } = useCommonStyles();
  const locale = useLang();

  const fieldsColumn: PruFilterItemDef[] = [
    {
      type: PruFilterItemType.ASYNC_DROPDOWN,
      field: 'examType',
      initialValue: formState.examType,
      displayName: Translation('recruitment.exam.examType'),
      fetchList: async () => {
        const res = await fetchLicenseExamExamOptions('EXAM_TYPE', { lang: locale }, dispatch);
        return res.map((item: any) => ({
          displayName: item || '-',
          value: item || '-',
        }));
      },
    },
    {
      type: PruFilterItemType.ASYNC_DROPDOWN,
      field: 'examName',
      initialValue: formState.examName,
      displayName: Translation('recruitment.exam.examName'),
      enableIfExist: ['examType'],
      fetchList: async () => {
        const res = await fetchLicenseExamExamOptions(
          'EXAM_NAME',
          { lang: locale, examType: formState.examType },
          dispatch,
        );
        return res.map((item: any) => ({
          displayName: item || '-',
          value: item || '-',
        }));
      },
    },
    {
      type: PruFilterItemType.DATE_RANGE,
      fieldFrom: 'examDateFrom',
      fieldTo: 'examDateTo',
      initialDateFrom: formState.startDate,
      initialDateTo: formState.endDate,
      displayName: Translation('recruitment.exam.examDate'),
    },
  ];

  const downloadRegistration = useCallback(async () => {
    try {
      await exportRegistration({ ...formState, lang: locale ?? 'en' }, dispatch);
    } catch (err) {
      handleDowloadFileError(err, dispatch);
    }
  }, [formState, dispatch]);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(
    formState,
    errorFieldConvertor(fieldsColumn),
  );


  return (
    <div className="tw-relative tw-container tw-bg-white tw-p-5 tw-mb-5 tw-rounded-md tw-h-1/2 tw-w-full">
      <div className="tw-flex tw-items-center tw-p-1">
        <div className="tw-w-full tw-flex">
          <div className={commonClasses.header}>{Translation('app.button.search')}</div>
        </div>
      </div>

      {fieldsColumn.map((item, index) => {
        if (item.type === PruFilterItemType.ASYNC_DROPDOWN)
          return (
            <div key={`export-${item.type}-${item.field}`}>
              <div className="tw-flex tw-items-center tw-m-4">
                <span className="tw-text-base tw-mr-1">{item.displayName}</span>
                <div className="tw-ml-3 tw-w-52">
                  <FormControl error={errorState.mandatory[item.field]} variant="outlined" margin="dense">
                    <AsyncAutocomplete
                      style={{ minWidth: '15rem' }}
                      value={formState[item.field] || ''}
                      onChange={(value) => {
                        onDismissErrorHandler(item.field, value);
                        filterDispatch({
                          type: 'CHANGE_FILTER',
                          payload: { field: item.field, value: value },
                        });
                      }}
                      getData={item.fetchList}
                    ></AsyncAutocomplete>
                    {errorState.mandatory.distributionChannel && (
                      <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                    )}
                  </FormControl>
                </div>
              </div>
            </div>
          );
        else if (item.type === PruFilterItemType.DATE_RANGE)
          return (
            <div key={`export-${item.type}-${index}-${item.fieldFrom}-${item.fieldTo}`}>
              <div className="tw-flex tw-items-center tw-m-4">
                <span className="tw-text-base tw-mr-1">{item.displayName}</span>
                <PruDatePicker
                  slotProps={{
                    textField: {
                      error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],
                    },
                  }}
                  format="DD/MM/YYYY"
                  value={formState[item.fieldFrom as string]}
                  onChange={(date) =>
                    filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldFrom, value: date } })
                  }
                />
                <div className="tw-h-1 tw-w-5">
                  <div className="tw-h-1/5 tw-w-full tw-bg-gray-900" />
                </div>
                <PruDatePicker
                  slotProps={{
                    textField: {
                      error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],
                      helperText: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`] && DATE_ERROR_TEXT,
                      style: { marginRight: 20 },
                    },
                  }}
                  format="DD/MM/YYYY"
                  value={formState[item.fieldTo as string]}
                  onChange={(date) =>
                    filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldTo, value: date } })
                  }
                />
              </div>
            </div>
          );
      })}

      <div className="tw-absolute tw-right-8 tw-top-1/2 tw-transform tw--translate-y-1/2">
        <Button variant="contained" color="secondary" onClick={downloadRegistration}>
          {Translation('button_export')}

        </Button>
      </div>
    </div>
  );
};
export default RegistrationExportPage;
