import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';
import { useDataProvider } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types';
import { PruTableSortTypeEnum } from 'src/app/common/components/PruTable/PruTable';
import { EventItem } from 'src/app/modules/event-v2/types';
import {
  EventListParam,
  fetchEventList,
  fetchEventCategoryList,
  fetchEventItem,
} from 'src/app/modules/event-v2/network';

type HookProps = {
  selectId?: string;
  onSelectItem: (id?: string) => void;
};

const initialState: EventListParam = {
  search: '',
  category: '',
  accessibility: '',
  eventDate: null,
  lastUpdatedBy: '',
  eventStatus: '',
  page: 1,
  limit: 20,
};

export const useSpecificEventDeeplink = ({ selectId, onSelectItem }: HookProps) => {
  const locale = useLang();
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [filterState, setFilterState] = useState<EventListParam>(initialState);
  const [eventList, setEventList] = useState<PaginateList<EventItem>>();
  const [selectedRow, setSelectedRow] = useState<EventItem>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<EventItem>>(
    async () => {
      try {
        return await fetchEventList(filterState, dispatch);
      } catch (err) {}
    },
    setEventList,
    false,
  );

  const reloadData = async () => {
    if (!selectedRow && selectId) {
      try {
        const res = await fetchEventItem(selectId, dispatch);
        setSelectedRow(res);
      } catch (err) {}
    }
  };

  useEffect(() => {
    reloadData();
  }, [selectId]);

  const fetchCategoryDropdown = async () => {
    const res = await fetchEventCategoryList({ pagination: false }, dispatch);
    return res.docs.map((item) => ({ displayName: item.name[locale] as string, value: item._id }));
  };

  const onSort = (newSortState: { key: string; value?: string }) => {
    const { key, value } = newSortState;
    if (value) {
      let newSort = '';
      switch (value) {
        case PruTableSortTypeEnum.ASC:
          newSort = key;
          break;
        case PruTableSortTypeEnum.DESC:
          newSort = `-${key}`;
          break;
      }
      setFilterState({ ...filterState, sort: newSort });
    } else {
      setFilterState({ ...filterState, sort: undefined });
    }
    refreshData();
  };

  const onClear = () => {
    setSelectedRow(undefined);
    onSelectItem(undefined);
    setShowDialog(true);
  };

  const onBack = () => {
    setSelectedRow(undefined);
    setShowDialog(false);
  };

  const onSubmit = () => {
    if (selectedRow) {
      onSelectItem(selectedRow._id);
    }
    setShowDialog(false);
  };

  return {
    showDialog,
    filterState,
    eventList,
    selectedRow,
    isLoading,
    setShowDialog,
    setFilterState,
    setSelectedRow,
    refreshData,
    fetchCategoryDropdown,
    onSort,
    onClear,
    onBack,
    onSubmit,
  };
};
