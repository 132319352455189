import React, { FC, useState } from 'react';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { scheduleBasePath } from './schedule-routes';
import { dateFormatToLocal, utcToLocalDate } from '../../../utils';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { TimeSlotsListItem, PaginateList } from '../../../types/license-exam-types';
import { deleteLicenseExamSchedule } from '../../../network/license-exam-crud';
import { useLang } from 'src/app/i18n';
type TimeSlotsListProps = {
  isLoading: boolean;
  timeSlotsList?: PaginateList<TimeSlotsListItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (sort: any) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const ScheduleList: FC<TimeSlotsListProps> = ({ isLoading, timeSlotsList, onRefresh, onChangePage, onSort }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const locale = useLang();

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteLicenseExamSchedule(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Exam Schedule deleted successfully`,
          },
        ]),
      );
    } catch (err) { }
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-application-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        disableBulkSelect
        isLoading={isLoading}
        onRefresh={onRefresh}
        onChangePage={onChangePage}
        title={Translation('recruitment.exam.schedule.list.title')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('recruitment.exam.schedule.add'),
            onClick: () => history.push(`${scheduleBasePath}/upload`),
          },
        ]}
        operationDef={[
          {
            title: Translation('section.common.operation.delete'),
            tooltipText: `${Translation('section.common.operation.delete')}`,
            onClick: (row) => setDialogState({ open: true, id: row.id }),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: 'id',
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'examType',
            displayName: `${Translation('recruitment.exam.examType')}`,
            renderData: (row) => `${row.examType}` || '-',
          },
          {
            keyIndex: 'examCode',
            displayName: `${Translation('recruitment.exam.examCode')}`,
            renderData: (row) => `${row.examCode}` || '-',
          },
          {
            keyIndex: 'examName',
            displayName: `${Translation('recruitment.exam.examName')}`,
            renderData: (row) => `${row.examName?.[locale] || row.examName?.['en']}` || '-',
          },
          {
            keyIndex: 'examCentre',
            displayName: `${Translation('recruitment.exam.examCentre')}`,
            renderData: (row) => `${row.examCentre}` || '-',
          },
          {
            keyIndex: 'examDate',
            displayName: `${Translation('recruitment.exam.examDate')}`,
            renderData: (row) => `${dateFormatToLocal(row.examDate as string)}` || '-',
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'EXAM_DATE', value: sort['examDate']?.toLocaleUpperCase() });
            },
          },
          {
            keyIndex: 'examDateStartTime',
            displayName: `${Translation('recruitment.exam.examDateStartTime')}`,
            renderData: (row) => `${row.startTime || '-'}`,
          },
          {
            keyIndex: 'examDateEndTime',
            displayName: `${Translation('recruitment.exam.examDateEndTime')}`,
            renderData: (row) => `${row.endTime}` || '-',
          },
          {
            keyIndex: 'examQuota',
            displayName: `${Translation('recruitment.exam.examQuota')}`,
            renderData: (row) => `${row.quota}` || '-',
          },
          {
            keyIndex: 'examRemainingQuota',
            displayName: `${Translation('recruitment.exam.examRemainingQuota')}`,
            renderData: (row) => `${row.remainingQuota}` || '-',
          },
          {
            keyIndex: 'lastUpdatedDate',
            displayName: `${Translation('recruitment.common.lastUpdatedAt')}`,
            renderData: (row) => `${utcToLocalDate(row.lastUpdatedDate)}` || '-',
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'LAST_UPDATED_AT', value: sort['lastUpdatedDate']?.toLocaleUpperCase() });
            },
          },
          {
            keyIndex: 'updatedBy',
            displayName: `${Translation('recruitment.common.lastUpdatedBy')}`,
            renderData: (row) => `${row.updatedBy}` || '-',
          },
        ]}
        dataSource={timeSlotsList?.data}
        totalPages={timeSlotsList?.totalPages}
        totalRecords={timeSlotsList?.totalNumbers}
      />
    </>
  );
};

export default ScheduleList;
