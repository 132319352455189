import { forEach } from 'lodash';
import { Dispatch } from 'react';
import { getDayEnd, getDayStart, getDayStartForStrapi, ignoreTimezone } from 'src/app/common/utils';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import {
  PopupEditItem,
  PopupItem,
  PopupListParam,
  PopupPaginateList,
  PopupPublishedMode,
  PopupValidMode,
} from '../types/operation-type';

const qs = require('qs');

export const fetchPopupList = async (
  locale: string,
  params: PopupListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<PopupPaginateList> => {
  let queryPath = `${ADMIN_URL}/content-manager/collection-types/api::popup.popup?`;
  // Query for publish and valid status filter
  queryPath += filterQueryForPublishAndValid(params['isPublish'], params['isValid'], queryPath);

  // Query for other filter
  forEach(params, (param, key) => {
    if (key === 'name') {
      var escapedParam = (param as string).replace(/([!@#$%^&*()+=\[\]\\';,./{}|":<>?~_-])/g, '\\$1');
      // queryPath += param ? `${key}_contains=${escapedParam}&` : '';
      queryPath += param ? `filters[${key}][$containsi]=${escapedParam}&` : '';
    } else if (typeof param === 'object') {
      if (param) {
        if (key.toLowerCase().includes('publishdatestart') && !isNaN(param.getTime())) {
          // queryPath += `_where[0][lastPublishTime_gte]=${getDayStartForStrapi(param)}&`;
          queryPath += `filters[lastPublishTime][$gte]=${getDayStartForStrapi(param)}&`;
        } else if (key.toLowerCase().includes('publishdateend') && !isNaN(param.getTime())) {
          // queryPath += `_where[0][lastPublishTime_lte]=${ignoreTimezone(getDayEnd(param))}&`;
          queryPath += `filters[lastPublishTime][$lte]=${ignoreTimezone(getDayEnd(param))}&`;
        }
      }
    } else if (key !== 'isPublish' && key !== 'isValid') {
      if (typeof param === 'string') {
        // queryPath += param ? `${key}_contains=${param}&` : '';
        queryPath += param ? `filters[${key}][$containsi]=${param}&` : '';
      } else {
        queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
      }
    }
  });

  //Query for sorting
  let sortingCount = 0;
  queryPath += `sort=`;
  forEach(sortKeys, (sortParam, key) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${encodeURIComponent(sortParam.key)}:${encodeURIComponent(
        sortParam.value,
      )}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `updatedAt:DESC`;
  }

  //Query for current language
  queryPath += `&locale=${locale}`;

  return apiClient
    .get(queryPath)
    .then((response) => {
      return {
        popupList: response.data.results,
        total: response.data.pagination.total,
        totalPages: response.data.pagination.pageCount,
        limit: response.data.pagination.pageSize,
        items: response.data.results,
        totalRecords: response.data.pagination.total,
        docs: response.data.results,
        hasNextPage: true,
        hasPrevPage: false,
        totalDocs: response.data.pagination.total,
      };
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchPopupItem = (id: string, dispatch?: Dispatch<any>): Promise<PopupItem> => {
  let queryPath = `${ADMIN_URL}/content-manager/collection-types/api::popup.popup/${id}`;
  return apiClient
    .get<PopupItem>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewPopup = async (
  data: PopupEditItem,
  locale: string,
  relatedId?: string,
  dispatch?: Dispatch<any>,
): Promise<PopupItem> => {
  let createURL = `${ADMIN_URL}/content-manager/collection-types/api::popup.popup?plugins[i18n][locale]=${locale}`;
  if (relatedId) {
    createURL += `&plugins[i18n][relatedEntityId]=${relatedId}`;
  }

  return apiClient
    .post<PopupItem>(createURL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyPopup = async (code: string, data: PopupEditItem, dispatch?: Dispatch<any>): Promise<PopupItem> => {
  const modifyURL = `${ADMIN_URL}/content-manager/collection-types/api::popup.popup/${code}`;
  return apiClient
    .put<PopupItem>(modifyURL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const publishPopup = async (dataId: string, dispatch?: Dispatch<any>): Promise<void> => {
  const publishURL = `${ADMIN_URL}/content-manager/collection-types/api::popup.popup/${dataId}/actions/publish`;
  return apiClient
    .post<void>(
      publishURL,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const unpublishPopup = async (dataId: string, dispatch?: Dispatch<any>): Promise<void> => {
  const unpublishURL = `${ADMIN_URL}/content-manager/collection-types/api::popup.popup/${dataId}/actions/unpublish`;
  return apiClient
    .post<void>(
      unpublishURL,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const filterQueryForPublishAndValid = (
  isPublishParam: string = '',
  isValidParam: string = '',
  queryPath: string,
): string => {
  let extendQuery: string = '';
  let query: any;

  if (isPublishParam === PopupPublishedMode.PUBLISHED) {
    switch (isValidParam) {
      case PopupValidMode.VALID:
        query = qs.stringify({
          filters: {
            publishedAt: {
              $notNull: true,
            },
            $and: [
              {
                $or: [
                  {
                    effectiveDate: {
                      $lte: ignoreTimezone(getDayStart(new Date())),
                    },
                  },
                  {
                    effectiveDate: {
                      $null: true,
                    },
                  },
                ],
              },
              {
                $or: [
                  {
                    expiredDate: {
                      $gte: ignoreTimezone(getDayEnd(new Date())),
                    },
                  },
                  {
                    expiredDate: {
                      $null: true,
                    },
                  },
                ],
              },
            ],
          },
        });

        break;
      case PopupValidMode.INVALID:
        // extendQuery = `_publicationState=live&`
        //   + `_where[0][_or][0][effectiveDate_gt]=${ignoreTimezone(getDayStart(new Date()))}&_where[0][_or][1][expiredDate_lt]=${ignoreTimezone(getDayEnd(new Date()))}&`;

        query = qs.stringify({
          filters: {
            publishedAt: {
              $notNull: true,
            },
            $or: [
              {
                effectiveDate: {
                  $gt: ignoreTimezone(getDayStart(new Date())),
                },
              },
              {
                expiredDate: {
                  $lt: ignoreTimezone(getDayEnd(new Date())),
                },
              },
            ],
          },
        });

        break;
      case PopupValidMode.ALL:
      default:
        // extendQuery = `_publicationState=live&`;
        query = qs.stringify({
          filters: {
            publishedAt: {
              $notNull: true,
            },
          },
        });
        break;
    }
  } else if (isPublishParam === PopupPublishedMode.UNPUBLISHED) {
    switch (isValidParam) {
      case PopupValidMode.VALID:
        // extendQuery = `_publicationState=live&published_at_null=true&`;
        query = qs.stringify({
          filters: {
            publishedAt: {
              $null: true,
            },
            $and: [
              {
                publishedAt: {
                  $notNull: true,
                },
              },
              {
                $or: [
                  {
                    effectiveDate: {
                      $lte: ignoreTimezone(getDayStart(new Date())),
                    },
                  },
                  {
                    effectiveDate: {
                      $null: true,
                    },
                  },
                ],
              },
              {
                $or: [
                  {
                    expiredDate: {
                      $gte: ignoreTimezone(getDayEnd(new Date())),
                    },
                  },
                  {
                    expiredDate: {
                      $null: true,
                    },
                  },
                ],
              },
            ],
          },
        });
        break;
      case PopupValidMode.INVALID:
      // extendQuery = `_publicationState=preview&published_at_null=true&`
      //   + `_where[0][_or][0][effectiveDate_gt]=${ignoreTimezone(getDayStart(new Date()))}&_where[0][_or][1][expiredDate_lt]=${ignoreTimezone(getDayEnd(new Date()))}&`;
      // break;
      case PopupValidMode.ALL:
      default:
        // extendQuery = `_publicationState=preview&published_at_null=true&`;
        query = qs.stringify({
          filters: {
            publishedAt: {
              $null: true,
            },
          },
        });
        break;
    }
  } else {
    switch (isValidParam) {
      case PopupValidMode.VALID:
        query = qs.stringify({
          filters: {
            publishedAt: {
              $notNull: true,
            },
            $and: [
              {
                $or: [
                  {
                    effectiveDate: {
                      $lte: ignoreTimezone(getDayStart(new Date())),
                    },
                  },
                  {
                    effectiveDate: {
                      $null: true,
                    },
                  },
                ],
              },
              {
                $or: [
                  {
                    expiredDate: {
                      $gte: ignoreTimezone(getDayEnd(new Date())),
                    },
                  },
                  {
                    expiredDate: {
                      $null: true,
                    },
                  },
                ],
              },
            ],
          },
        });
        break;
      case PopupValidMode.INVALID:
        // extendQuery = `_where[0][_or][0][effectiveDate_gt]=${ignoreTimezone(getDayStart(new Date()))}&_where[0][_or][1][expiredDate_lt]=${ignoreTimezone(getDayEnd(new Date()))}&`
        //   + `_where[0][_or][2][published_at_null]=true&`
        query = qs.stringify({
          filters: {
            $or: [
              {
                effectiveDate: {
                  $gt: ignoreTimezone(getDayStart(new Date())),
                },
              },
              {
                expiredDate: {
                  $lt: ignoreTimezone(getDayEnd(new Date())),
                },
              },
              {
                publishedAt: {
                  $null: true,
                },
              },
            ],
          },
        });
        break;
      case PopupValidMode.ALL:
      default:
        break;
    }
  }
  return query ? `${query}&` : '';
};
