import React, { FC, memo, useMemo } from 'react';
import { useIntl, IntlProvider, FormattedMessage } from 'react-intl';
import {
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './noti-template-form.style';
import { useNotiTemplateForm } from './noti-template-form.hook';
import { AutoNotiErrorAction, AutoNotiFormAction, ErrorState } from '../../notification-config-edit.hook';
import {
  AutoNotiChannelEnum,
  AutoNotiTemplateEnum,
  AutoNotiTriggerMethodEnum,
  AutoNotiTriggerTypeEnum,
  NotificationTemplateItem,
  i18nConfigItem,
} from 'src/app/modules/Notification/types/notification-types';
import { PruTimePicker } from 'src/app/common/components/PruDatePicker';
import CheckGroup from 'src/app/common/components/CheckGroup';
import { LINK_INVALID_ERROR, OpenInDevice, RedirectType } from 'src/app/modules/Notification/constants';
import {
  AUTO_NOTI_FORMAT_ERROR_TEXT,
  AUTO_NOTI_TRIGGER_TIME_DUPLICATED,
  MANDATORY_FIELD_ERROR_TEXT,
  Routes,
  ZERO_ERROR_TEXT,
} from 'src/app/common/constants';
import { isEqual, isObject } from 'lodash';
// import SpecificEventDeeplink from 'src/app/common/components/SpecificDeeplinkList/event/index';
import { SpecificEventDeeplink } from 'src/app/common/components/SpecificDeeplinkList/event-v2';
import SpecificTrainingDeeplink from 'src/app/common/components/SpecificDeeplinkList/training/index';
import SpecificSurveyDeeplink from 'src/app/common/components/SpecificDeeplinkList/survey/index';
import { isEmptyArray } from 'formik';
import moment from 'moment';
import { allMessages, regionLocale } from 'src/app/i18n';

type ComponentProps = ParamsProps & {
  index: number;
  locale: Array<string>;
  formState: NotificationTemplateItem;
  isSubmit: boolean;
  formDispatch: (value: AutoNotiFormAction) => void;
  errorState: ErrorState;
  errorDispatch: (value: AutoNotiErrorAction) => void;
  isLinkValid: (index: number, link?: string) => boolean;
  removeTemplate: (index: number) => void;
};

export const NotiTemplateFormComponent: React.FC<ComponentProps> = memo(
  ({
    index,
    formState,
    errorState,
    locale,
    formDispatch,
    errorDispatch,
    isLinkValid,
    removeTemplate,
  }: ComponentProps) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });
    const triggerTypeList: { [index: string]: string } = {
      accept_sla_expiry: 'notificationTemplate.triggerType.accpetExpiry',
      contact_sla_expiry: 'notificationTemplate.triggerType.contactExpiry',
      meet_sla_expiry: 'notificationTemplate.triggerType.meetExpiry',
      proposal_sla_expiry: 'notificationTemplate.triggerType.proposalExpiry',
      application_sla_expiry: 'notificationTemplate.triggerType.applicationExpiry',
      accept_sla_pull_back: 'notificationTemplate.triggerType.acceptPullbackSLA',
      contact_sla_pull_back: 'notificationTemplate.triggerType.contactPullbackSLA',
    };

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    // custom-hook
    const { deeplink, setDeepLink, AppRoutes, isSpecificLink, onSelectItem, dialogState, setDialogState } =
      useNotiTemplateForm({
        index,
        formState,
        errorState,
        formDispatch,
        errorDispatch,
      });
    return (
      <>
        <Dialog open={dialogState.open} onClose={() => setDialogState({ open: false, id: index })}>
          <div className={styles.dialogContainer}>
            <div className={styles.margin_b30}>
              <span className={styles.subHeader}>Are you sure to remove the setting?</span>
            </div>
            <div className={styles.dialogButton}>
              <Button variant="contained" onClick={() => setDialogState({ open: false, id: index })}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setDialogState({ open: false, id: index });
                  removeTemplate(index);
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Dialog>
        <div className={styles.container} key={index}>
          <div className={`${styles.textAreaRowContainer} ${styles.textRowHeight}`}>
            <div className={styles.fieldContainer}>
              <span className={styles.subHeader}>{Translation('component.formLabel.triggertime')}</span>
              <span className={styles.mandatory}>*</span>
            </div>
            <div className={styles.content}>
              {formState.template === AutoNotiTemplateEnum.SLA_EXPIRY_REMINDER ? (
                <div className={styles.textAreaRowContainer}>
                  {formState.triggerTime.method === AutoNotiTriggerMethodEnum.WHEN ? null : (
                    <TextField
                      className={styles.margin_r8}
                      variant="outlined"
                      margin="dense"
                      value={formState.triggerTime.param}
                      error={
                        errorState.triggerTime.param ||
                        errorState.triggerTime.matchReg ||
                        errorState.duplicatedTriggerTime
                      }
                      onChange={(e) => {
                        formDispatch({
                          type: 'MODIFY_TEMPLATE',
                          index: index,
                          payload: {
                            field: 'triggerTime',
                            value: { ...formState.triggerTime, param: e.target.value },
                          },
                        });
                      }}
                    />
                  )}

                  <TextField
                    className={styles.margin_r8}
                    variant="outlined"
                    select
                    margin="dense"
                    error={errorState.duplicatedTriggerTime}
                    value={formState.triggerTime.method}
                    onChange={(e) => {
                      formDispatch({
                        type: 'MODIFY_TEMPLATE',
                        index: index,
                        payload: {
                          field: 'triggerTime',
                          value: { ...formState.triggerTime, method: e.target.value },
                        },
                      });
                    }}
                  >
                    {Object.values(AutoNotiTriggerMethodEnum).map((item) => {
                      if (item !== AutoNotiTriggerMethodEnum.PERIODIC) {
                        return (
                          <MenuItem key={item} value={item}>
                            {item.charAt(0).toUpperCase() + item.slice(1)}
                          </MenuItem>
                        );
                      }
                    })}
                  </TextField>

                  <TextField
                    variant="outlined"
                    select
                    margin="dense"
                    error={errorState.duplicatedTriggerTime}
                    value={formState.triggerTime.type}
                    onChange={(e) => {
                      if (
                        e.target.value === AutoNotiTriggerTypeEnum.ACCEPT_SLA_PULL_BACK ||
                        e.target.value === AutoNotiTriggerTypeEnum.CONTACT_SLA_PULL_BACK
                      ) {
                        formDispatch({
                          type: 'MODIFY_TEMPLATE',
                          index: index,
                          payload: {
                            field: 'triggerTime',
                            value: {
                              ...formState.triggerTime,
                              type: e.target.value,
                              method: AutoNotiTriggerMethodEnum.WHEN,
                            },
                          },
                        });
                      } else {
                        formDispatch({
                          type: 'MODIFY_TEMPLATE',
                          index: index,
                          payload: {
                            field: 'triggerTime',
                            value: { ...formState.triggerTime, type: e.target.value },
                          },
                        });
                      }
                    }}
                  >
                    {Object.keys(triggerTypeList).map((item) => {
                      return (
                        <MenuItem key={item} value={item}>
                          {Translation(triggerTypeList[item])}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  <span className={styles.margin_l8}>(e.g. 3d 12h 30m)</span>
                </div>
              ) : null}
              {formState.template === AutoNotiTemplateEnum.SYS_TURN_OFF_ACCEPT_REMINDER ? (
                <span>{Translation('notificationTemplate.triggerType.sysToggleOff')}</span>
              ) : null}
              {formState.template === AutoNotiTemplateEnum.TURNED_OFF_ACCEPT_REMINDER ? (
                <div className={styles.textAreaRowContainer}>
                  <span className={styles.margin_r8}>{Translation('notificationTemplate.triggerType.offPart1')}</span>
                  <PruTimePicker
                    ampm={false}
                    value={moment(formState.triggerTime.param, 'HH:mm').toDate()}
                    onChange={(e) => {
                      formDispatch({
                        type: 'MODIFY_TEMPLATE',
                        index: index,
                        payload: {
                          field: 'triggerTime',
                          value: { ...formState.triggerTime, param: moment(e).format('HH:mm') },
                        },
                      });
                    }}
                  />
                  <span className={styles.margin_l30_r8}>
                    {Translation('notificationTemplate.triggerType.offPart2')}
                  </span>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    value={formState.triggerTime.value}
                    error={
                      errorState.triggerTime.value ||
                      errorState.triggerTime.valueIsNum ||
                      errorState.duplicatedTriggerTime
                    }
                    onChange={(e) => {
                      formDispatch({
                        type: 'MODIFY_TEMPLATE',
                        index: index,
                        payload: {
                          field: 'triggerTime',
                          value: {
                            ...formState.triggerTime,
                            value: isNaN(Number(e.target.value)) ? 0 : Number(e.target.value),
                          },
                        },
                      });
                    }}
                  />
                  <span className={styles.margin_l8}>{Translation('notificationTemplate.triggerType.offPart3')}</span>
                </div>
              ) : null}
            </div>
            <Tooltip title="Delete">
              <IconButton onClick={() => setDialogState({ open: true, id: index })}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>

          {formState.template === AutoNotiTemplateEnum.SLA_EXPIRY_REMINDER ? (
            <div className={styles.margin_l160}>
              <FormHelperText>
                <span className={styles.formHelperText}>
                  {(errorState.triggerTime.param && MANDATORY_FIELD_ERROR_TEXT) ||
                    (errorState.triggerTime.matchReg && AUTO_NOTI_FORMAT_ERROR_TEXT) ||
                    (errorState.duplicatedTriggerTime && AUTO_NOTI_TRIGGER_TIME_DUPLICATED)}
                </span>
              </FormHelperText>
            </div>
          ) : null}

          {formState.template === AutoNotiTemplateEnum.TURNED_OFF_ACCEPT_REMINDER ? (
            <div className={styles.margin_l160}>
              <FormHelperText>
                <span className={styles.formHelperText}>
                  {(errorState.triggerTime.value && MANDATORY_FIELD_ERROR_TEXT) ||
                    (errorState.triggerTime.valueIsNum && ZERO_ERROR_TEXT) ||
                    (errorState.duplicatedTriggerTime && AUTO_NOTI_TRIGGER_TIME_DUPLICATED)}
                </span>
              </FormHelperText>
            </div>
          ) : null}

          <div className={`${styles.textAreaRowContainer} ${styles.textRowHeight}`}>
            <div className={styles.fieldContainer}>
              <span className={styles.subHeader}>{Translation('component.formLabel.channel')}</span>
              <span className={styles.mandatory}>*</span>
            </div>
            <div className={styles.content}>
              <FormControl error>
                <CheckGroup
                  disabled={false}
                  options={[
                    { label: AutoNotiChannelEnum.Inbox, value: AutoNotiChannelEnum.Inbox },
                    { label: AutoNotiChannelEnum.PUSH, value: AutoNotiChannelEnum.PUSH },
                  ]}
                  value={formState.channel}
                  onChange={(e) => {
                    formDispatch({
                      type: 'MODIFY_TEMPLATE',
                      index: index,
                      payload: { field: 'channel', value: e },
                    });
                    errorDispatch({
                      type: 'MODIFY_ERROR',
                      index: index,
                      payload: { field: 'channel', value: isEmptyArray(e) },
                    });
                  }}
                />
              </FormControl>
            </div>
          </div>

          <div className={styles.margin_l160}>
            <FormHelperText>
              <span className={styles.formHelperText}>{errorState.channel && MANDATORY_FIELD_ERROR_TEXT}</span>
            </FormHelperText>
          </div>

          {formState.channel.includes(AutoNotiChannelEnum.Inbox) ? (
            <div className={`${styles.textAreaRowContainer} ${styles.textRowHeight}`}>
              <div className={styles.fieldContainer}>
                <span className={styles.subHeader}>{Translation('component.formLabel.inbox-category')}</span>
              </div>
              <div className={styles.content}>PRULeads</div>
            </div>
          ) : null}

          {locale.map((item, idx) => {
            return (
              <div key={idx}>
                <div className={`${styles.textAreaRowContainer} ${styles.textRowHeight}`}>
                  <IntlProvider locale={item} messages={allMessages[item]}>
                    <div className={styles.fieldContainer}>
                      <span className={styles.subHeader}>
                        <FormattedMessage id={`navBar.lang.${item}`} />
                      </span>
                    </div>
                    <div className={styles.content}>
                      <span className={`${styles.field} ${styles.remark}`}>
                        <FormattedMessage id="component.formLabel.notification-description" />
                      </span>
                    </div>
                  </IntlProvider>
                </div>
                <div className={`${styles.textAreaRowContainer} ${styles.textRowHeight}`}>
                  <div className={styles.fieldContainer}>
                    <span className={styles.subHeader}>{Translation('component.formLabel.notification-title')}</span>
                    <span className={styles.mandatory}>*</span>
                  </div>
                  <div className={styles.content}>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      value={formState.title[item]}
                      error={errorState.title[item]}
                      onChange={(e) => {
                        formDispatch({
                          type: 'MODIFY_TEMPLATE',
                          index: index,
                          payload: { field: 'title', value: { ...formState.title, [item]: e.target.value } },
                        });
                        errorDispatch({
                          type: 'MODIFY_ERROR',
                          index: index,
                          payload: {
                            field: 'title',
                            value: { ...errorState.title, [item]: e.target.value === '' },
                          },
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.margin_l160}>
                  <FormHelperText>
                    <span className={styles.formHelperText}>
                      {errorState.title[item] && MANDATORY_FIELD_ERROR_TEXT}
                    </span>
                  </FormHelperText>
                </div>
                <div className={`${styles.textAreaRowContainer} ${styles.textRowHeight}`}>
                  <div className={styles.fieldContainer}>
                    <span className={styles.subHeader}>
                      {Translation('component.globalHeader.notificationContent')}
                    </span>
                    <span className={styles.mandatory}>*</span>
                  </div>
                  <div className={styles.content}>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      value={formState.content[item]}
                      error={errorState.content[item]}
                      onChange={(e) => {
                        formDispatch({
                          type: 'MODIFY_TEMPLATE',
                          index: index,
                          payload: { field: 'content', value: { ...formState.content, [item]: e.target.value } },
                        });
                        errorDispatch({
                          type: 'MODIFY_ERROR',
                          index: index,
                          payload: {
                            field: 'content',
                            value: { ...errorState.content, [item]: e.target.value === '' },
                          },
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.margin_l160}>
                  <FormHelperText>
                    <span className={styles.formHelperText}>
                      {errorState.content[item] && MANDATORY_FIELD_ERROR_TEXT}
                    </span>
                  </FormHelperText>
                </div>
              </div>
            );
          })}

          <div className={`${styles.textAreaRowContainer} ${styles.textRowHeight}`}>
            <div className={styles.checkboxFieldContainer}>
              <span className={styles.subHeader}>{Translation('component.formLabel.redirectTo')}</span>
              <span className={styles.mandatory}>*</span>
            </div>
            <FormControl error>
              <RadioGroup
                className={styles.radioGroup}
                name="redirectTo"
                value={formState.redirectParams?.redirectTo}
                onChange={(e) => {
                  if (e.target.value === RedirectType.pageInApp) {
                    formDispatch({
                      type: 'MODIFY_TEMPLATE',
                      index: index,
                      payload: {
                        field: 'redirectParams',
                        value: {
                          ...formState.redirectParams,
                          redirectTo: e.target.value,
                          link:
                            formState.template === AutoNotiTemplateEnum.SLA_EXPIRY_REMINDER
                              ? AppRoutes['route.pulseleads.detail']
                              : AppRoutes['route.pulseleads.opportunity'],
                        },
                      },
                    });
                  } else if (e.target.value === RedirectType.URL) {
                    formDispatch({
                      type: 'MODIFY_TEMPLATE',
                      index: index,
                      payload: {
                        field: 'redirectParams',
                        value: {
                          ...formState.redirectParams,
                          redirectTo: e.target.value,
                          openBy: OpenInDevice.external,
                          link: '',
                        },
                      },
                    });
                  } else {
                    formDispatch({
                      type: 'MODIFY_TEMPLATE',
                      index: index,
                      payload: {
                        field: 'redirectParams',
                        value: {
                          ...formState.redirectParams,
                          redirectTo: e.target.value,
                          link: '',
                        },
                      },
                    });
                  }
                  errorDispatch({
                    type: 'MODIFY_ERROR',
                    index: index,
                    payload: {
                      field: 'redirectParams',
                      value: {
                        ...errorState.redirectParams,
                        redirectTo: e.target.value === '',
                        openBy: false,
                        link: false,
                      },
                    },
                  });
                }}
              >
                {Object.entries(RedirectType).map(([label, value]) => (
                  <FormControlLabel
                    key={value}
                    value={value}
                    control={<Radio />}
                    label={Translation(`component.formSelectItem.${label}`)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>

          <div className={styles.margin_l160}>
            <FormHelperText>
              <span className={styles.formHelperText}>
                {errorState.redirectParams.redirectTo && MANDATORY_FIELD_ERROR_TEXT}
              </span>
            </FormHelperText>
          </div>

          {formState.redirectParams?.redirectTo === 'url' ? (
            <>
              <div className={`${styles.textAreaRowContainer} ${styles.textRowHeight}`}>
                <div className={styles.checkboxFieldContainer}>
                  <span className={styles.subHeader}>{Translation('component.formLabel.openBy')}</span>
                  <span className={styles.mandatory}>*</span>
                </div>
                <FormControl error>
                  <RadioGroup
                    className={styles.radioGroup}
                    name="redirectTo"
                    value={formState.redirectParams?.openBy}
                    onChange={(e) => {
                      formDispatch({
                        type: 'MODIFY_TEMPLATE',
                        index: index,
                        payload: {
                          field: 'redirectParams',
                          value: { ...formState.redirectParams, openBy: e.target.value },
                        },
                      });
                      errorDispatch({
                        type: 'MODIFY_ERROR',
                        index: index,
                        payload: {
                          field: 'redirectParams',
                          value: {
                            ...errorState.redirectParams,
                            openBy:
                              formState.redirectParams?.redirectTo === RedirectType.URL ? e.target.value === '' : false,
                          },
                        },
                      });
                    }}
                  >
                    {Object.entries(OpenInDevice).map(([label, value]) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={<Radio />}
                        label={Translation(`component.formSelectItem.${label}`)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
              <div className={styles.margin_l160}>
                <FormHelperText>
                  <span className={styles.formHelperText}>
                    {errorState.redirectParams.openBy && MANDATORY_FIELD_ERROR_TEXT}
                  </span>
                </FormHelperText>
              </div>
              <div className={`${styles.textAreaRowContainer} ${styles.textRowHeight}`}>
                <div className={styles.fieldContainer}>
                  <span className={styles.subHeader}>
                    {Translation('component.formLabel.link')}
                    <span className={styles.mandatory}>*</span>
                  </span>
                </div>
                <div className={styles.content}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={formState.redirectParams.link}
                    error={errorState.redirectParams.link}
                    onChange={(e) => {
                      formDispatch({
                        type: 'MODIFY_TEMPLATE',
                        index: index,
                        payload: {
                          field: 'redirectParams',
                          value: { ...formState.redirectParams, link: e.target.value },
                        },
                      });
                      errorDispatch({
                        type: 'MODIFY_ERROR',
                        index: index,
                        payload: {
                          field: 'redirectParams',
                          value: {
                            ...errorState.redirectParams,
                            link:
                              formState.redirectParams?.redirectTo === RedirectType.URL
                                ? !isLinkValid(index, e.target.value)
                                : false,
                          },
                        },
                      });
                    }}
                  />
                </div>
              </div>
              <div className={styles.margin_l160}>
                <FormHelperText>
                  <span className={styles.formHelperText}>
                    {errorState.redirectParams.link && Translation(LINK_INVALID_ERROR)}
                  </span>
                </FormHelperText>
              </div>
            </>
          ) : null}
          {formState.redirectParams?.redirectTo === 'app' ? (
            <>
              <div className={`${styles.textAreaRowContainer}`}>
                <div className={styles.fieldContainer}>
                  <span className={styles.subHeader}>
                    {Translation('component.formLabel.link')}
                    <span className={styles.mandatory}>*</span>
                  </span>
                </div>
                <div className={classes.content}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    value={formState.category === 'inbox_secretary' ? JSON.parse(deeplink as string).link : deeplink}
                    error={errorState.redirectParams.link}
                    onChange={(e) => {
                      const currentValue: any = e.target.value;
                      let selectValue: any;

                      try {
                        selectValue = JSON.parse(currentValue);
                      } catch (e) {
                        selectValue = currentValue;
                      }

                      const selectedItem: any = Object.entries(AppRoutes).find(([label, value]: any) => {
                        const hasParams = isObject(selectValue);
                        if (typeof value != 'string' && hasParams) {
                          return value.link === selectValue.link && isEqual(value.params, selectValue.params);
                        }
                        return value === selectValue;
                      });

                      if (selectedItem && typeof selectedItem[1] !== 'string') {
                        formDispatch({
                          type: 'MODIFY_TEMPLATE',
                          index: index,
                          payload: {
                            field: 'redirectParams',
                            value: {
                              ...formState.redirectParams,
                              params: selectedItem[1].params,
                              link: selectedItem[1].link,
                            },
                          },
                        });
                        setDeepLink(
                          JSON.stringify({
                            link: selectedItem[1].link,
                            params: selectedItem[1].params,
                          }),
                        );
                      } else {
                        setDeepLink(selectValue);
                        formDispatch({
                          type: 'MODIFY_TEMPLATE',
                          index: index,
                          payload: {
                            field: 'redirectParams',
                            value: { ...formState.redirectParams, link: selectValue, params: null },
                          },
                        });
                      }
                      errorDispatch({
                        type: 'MODIFY_ERROR',
                        index: index,
                        payload: {
                          field: 'redirectParams',
                          value: {
                            ...errorState.redirectParams,
                            link:
                              formState.redirectParams?.redirectTo === RedirectType.pageInApp
                                ? !isLinkValid(index, e.target.value)
                                : false,
                          },
                        },
                      });
                    }}
                  >
                    {Object.entries(AppRoutes).map(([label, value]: any) => {
                      if (typeof value !== 'string') {
                        value = JSON.stringify(value);
                      }
                      return (
                        <MenuItem key={value} value={value}>
                          {Translation(label)}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  {formState.redirectParams.link === Routes.SPECIFIC_EVENT_LINK && (
                    <SpecificEventDeeplink
                      selectId={formState?.redirectParams?.params?.id}
                      onSelectItem={onSelectItem}
                    />
                  )}
                  {formState.redirectParams.link === Routes.SPECIFIC_SURVEY_LINK && (
                    <SpecificSurveyDeeplink
                      onSelectItem={onSelectItem}
                      selectId={formState?.redirectParams?.params?.id}
                    />
                  )}
                  {formState.redirectParams.link === Routes.SPECIFIC_TRAINING_LINK && (
                    <SpecificTrainingDeeplink
                      onSelectItem={onSelectItem}
                      selectId={formState?.redirectParams?.params?.id}
                    />
                  )}

                  {isSpecificLink && errorState.redirectParams.params && (
                    <FormHelperText className={styles.formHelperText}>
                      {Translation('app.input.placeholder.please-select')}
                    </FormHelperText>
                  )}
                </div>
              </div>
              <div className={styles.margin_l160}>
                <FormHelperText>
                  <span className={styles.formHelperText}>
                    {errorState.redirectParams.link && MANDATORY_FIELD_ERROR_TEXT}
                  </span>
                </FormHelperText>
              </div>
            </>
          ) : null}
        </div>
      </>
    );
  },
);
