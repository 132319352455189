import { range, get } from 'lodash';
import { useRef, useEffect, useCallback } from 'react';
import { CONFIG_STORAGE_KEY } from '../constants';
import { FormMode } from '../types';

export const isValueExist = (field: any) => {
  return field !== undefined && field !== null;
};

export const numberSeparator = (num: string) => {
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const removeSeparator = (str: string) => {
  return str.replace(/,/g, '');
};

export const isValidURL = (str: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

export const preFillZero = (code: string): string => {
  const newCode = code.trim();
  if (newCode.length < 8) {
    const text = `${range(8 - newCode.length)
      .map(() => `0`)
      .join('')}${newCode}`;
    return text.trim();
  } else {
    return newCode.trim();
  }
};

export const preFillZeroList = (codeList: string[]): string[] => {
  return codeList.map((code) => {
    return preFillZero(code);
  });
};

// Input Byte, Output MB
export const fileSizeConvertorMb = (size: number) => {
  return `${(size / 1024 / 1024).toFixed(2)}MB`;
};

// export const NumberGreaterThanZeroPattern = /^([1-9]\d*(\.\d*[1-9])?)|(0\.\d*[1-9])$/;
export const NumberGreaterThanZeroPattern = new RegExp(/^([1-9]\d*(\.\d*[1-9])?)|(0\.\d*[1-9])$/);

export const disableInputInvalidValueForWeight = (minValue?: string) => {
  return {
    step: 'any',
    min: minValue ? minValue : 1,
    onKeyPress: (event: any) => {
      const invalidChars = ['-', '+', 'e', 'E'];
      if (invalidChars.indexOf(event.key) !== -1) {
        event.preventDefault();
      }
    },
    onInput: (event: any) => {
      const invalidChars = ['-', '+', 'e', 'E'];
      if (invalidChars.indexOf(event.key) !== -1) {
        event.preventDefault();
      }
    },
    onPaste: (event: any) => {
      event.preventDefault();
    },
  };
};

export function useDebounce(fn: Function, delay: number, dep = []) {
  const initData: { fn: Function; timer: NodeJS.Timeout | null } = {
    fn,
    timer: null,
  };
  const { current } = useRef(initData);
  useEffect(
    function () {
      current.fn = fn;
      // eslint-disable-next-line
    },
    [fn],
  );

  return useCallback(function f(...args: any[]) {
    if (current.timer) {
      clearTimeout(current.timer);
    }
    current.timer = setTimeout(() => {
      current.fn(...args);
    }, delay);
    // eslint-disable-next-line
  }, dep);
}

export function useThrottle(fn: Function, delay: number, dep = []) {
  const initData: { fn: Function; timer: NodeJS.Timeout | null } = {
    fn,
    timer: null,
  };
  const { current } = useRef(initData);
  useEffect(
    function () {
      current.fn = fn;
      // eslint-disable-next-line
    },
    [fn],
  );

  return useCallback(function f(...args: any[]) {
    if (!current.timer) {
      current.timer = setTimeout(() => {
        current.timer = null;
      }, delay);
      current.fn(...args);
    }
    // eslint-disable-next-line
  }, dep);
}

export const csvHandler = (file: File): Promise<string[][]> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      const csvData = String(reader.result);
      const rows = csvData.split('\n');
      resolve(rows.map((row) => row.split(',')));
    };
    if (file) {
      reader.readAsBinaryString(file);
    }
  });
};

export const getCurrentConfig = (user: any) => {
  const region = user?.region || '';
  const channel = user?.channel || '';

  let config;
  try {
    config = require(`../../../../src/channelConfig/${region.toLowerCase()}_${channel.toLowerCase()}.json`);
  } catch (e) {
    config = require(`../../../../src/channelConfig/default.json`);
  }

  return config;
};

export const convertArrayToObject = (array: any[], key: string) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const pathExtractor = (currentPath: string, basePath: string) => {
  const path = currentPath.split(basePath).pop();
  if (path) {
    const pathArr = path.split('/');
    const formMode = pathArr[1] as FormMode;
    if (formMode === FormMode.CREATE) {
      return {
        formMode,
      };
    } else if (formMode === FormMode.EDIT || formMode === FormMode.VIEW) {
      return {
        formMode,
      };
    }
  }
  return {
    formMode: FormMode.CREATE,
  };
};

export const getConfigurations: any = () => {
  const configs = JSON.parse(window.localStorage.getItem(CONFIG_STORAGE_KEY) || '{}');
  return configs || {};
};

/**
 * get config item by json path
 * example: getConfig('Sales.material.enabledCustomWeight', true);
 * @param jsonPath
 * @param defaultValue
 * @returns
 */
export function getConfigItem(jsonPath: string, defaultValue: any) {
  const config = getConfigurations();
  return get(config, jsonPath, defaultValue);
}
/**
 * config item: if enabled material-custom-weight
 * @returns
 */
export function getEnabledCustomWeight() {
  // return false;
  return getConfigItem('Sales.material.enabledCustomWeight', true);
}

export function getDisplayName(agentInfo: any, lang: string): string {
  const langMapping = {
    en: 'enUs',
    'zh-Hant': 'zhHk',
  };
  const langKey = get(langMapping, lang, 'enUs');
  let displayName = get(agentInfo, `displayName.${langKey}`);
  if (!displayName || typeof displayName !== 'string') {
    displayName = get(agentInfo, `name.${langKey}.displayName`, get(agentInfo, `name.enUs.displayName`, '--'));
  }
  return displayName;
}

Array.prototype.insert = function (index, item) {
  return this.splice(index, 0, item);
};

/**
 * is a valid mail address
 * @param mailAddr target mail address
 * @returns true / false
 */
export function isEmail(mailAddr: string): boolean {
  if (!mailAddr) {
    return false;
  }
  // const regEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  const regEmail = new RegExp(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/);
  return regEmail.test(mailAddr);
}

export function objectValueTransform<
  T extends Record<M, K>,
  K extends T[keyof T],
  M extends keyof T,
  V extends unknown,
>(obj: T, transformer: (value: K) => V): Record<M, V> {
  return Object.fromEntries(Object.entries(obj).map((entry) => [entry[0], transformer(entry[1] as K)])) as Record<M, V>;
}

export function toTitleCase(str: string) {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export function deleteEmptyString(json: Record<string, unknown>) {
  for (const key in json) {
    if (json[key] === '') {
      delete json[key];
    }
  }
  return json;
}

export const isDateValid = (date: Date) => {
  return !Number.isNaN(date.getTime());
};

export const isAlphabet = (str: string): boolean => {
  return Boolean(str.match(/^([a-z]|[A-Z])+$/g));
};

export const getFullName = (firstName?: string, lastName?: string) => {
  if (!lastName || isAlphabet(lastName)) {
    return `${firstName || ''} ${lastName || ''}`;
  }
  return `${lastName || ''} ${firstName || ''}`;
};
